import React from "react";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms";
import { useFormik } from "formik";
import { Alert, Button, Divider } from "antd";

import { BiLinkExternal } from "react-icons/bi";

const ContactFormProf = ({ currentPath }) => {
  const netlify = useNetlifyForm({
    name: "Allesoverantistolling.nl - Contactformulier Professionals",
    action: "/thanks",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {},
  });
  const { handleSubmit, handleChange, handleBlur, errors, values } = useFormik({
    initialValues: {
      email: "",
      message: "",
      organisation: "",
      name: "",
      url: currentPath,
    },
    onSubmit: (values) => {
      netlify.handleSubmit(null, values);
    },
    validate: (values) => {
      const errors = {};
      if (!values.acceptTerms) {
        errors.acceptTerms = "Het accepteren van de voorwaarden is verplicht";
      }
      return errors;
    },
  });

  return (
    <NetlifyFormProvider {...netlify}>
      <NetlifyFormComponent onSubmit={handleSubmit}>
        <Honeypot />
        <div>
          <label
            htmlFor="name"
            style={{
              display: "block",
              fontSize: "16px",
              margin: "10px 0",
            }}
          >
            Naam:
          </label>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            className="rs-input"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            style={{
              display: "block",
              fontSize: "16px",
              margin: "10px 0",
            }}
          >
            E-mailadres:
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className="rs-input"
            required
          />
        </div>
        <div>
          <label
            htmlFor="organisation"
            style={{
              display: "block",
              fontSize: "16px",
              margin: "10px 0",
            }}
          >
            Organisatie:
          </label>
          <input
            type="text"
            name="organisations"
            id="organisation"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className="rs-input"
            required
          />
        </div>
        <div>
          <label
            htmlFor="message"
            style={{
              display: "block",
              fontSize: "16px",
              margin: "10px 0",
            }}
          >
            Bericht:
          </label>
          <textarea
            type="text"
            name="message"
            id="message"
            rows="4"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.message}
            className="rs-input"
            required
          />
        </div>

        <div>
          <input
            type="text"
            hidden
            name="url"
            id="url"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.url}
          />
        </div>
        <Divider />
        <div>
          <label
            htmlFor="acceptTerms"
            style={{
              display: "block",
              fontSize: "14px",
              marginTop: "16px",
              marginBottom: "6px",
              width: "33%",
            }}
          >
            <input
              name="acceptTerms"
              type="checkbox"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.acceptTerms}
              style={{ marginRight: "8px" }}
            />{" "}
            Ik begrijp dat de door mij verstrekte persoonsgegevens ten behoeve
            van het contactformulier op allesoverantistolling.nl vertrouwelijk
            worden behandeld. Gegevens worden zonder toestemming niet verstrekt
            aan derden.
          </label>
          {errors.acceptTerms ? (
            <Alert
              message={errors.acceptTerms}
              type="warning"
              showIcon
              style={{ marginTop: "8px" }}
            />
          ) : null}
        </div>
        {netlify.error && (
          <Alert
            message="Er gaat helaas wat fout met het versturen van jouw bericht. Probeer het later nog eens!"
            type="error"
            showIcon
          />
        )}
        {netlify.success && (
          <Alert
            message="Het bericht is verzonden, hartelijk dank!"
            type="success"
            showIcon
          />
        )}
        <div>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!values.acceptTerms}
            style={{ marginTop: "16px" }}
            icon={<BiLinkExternal />}
            size="large"
            danger
          >
            Versturen
          </Button>
        </div>
      </NetlifyFormComponent>
    </NetlifyFormProvider>
  );
};

export default ContactFormProf;
