import * as React from "react";
import { Grid, Row, Col } from "rsuite";
import HeaderLarge from "components/section/headerLarge";
import LayoutMain from "components/layout/main";
import SectionWrapper from "components/section/sectionWrapper";
import ContactFormProf from "components/ui/contactFormProf";

const ContactPage = () => {
  return (
    <LayoutMain>
      <HeaderLarge title="Contact Professional" description="Heb jij een vraag of een opmerking over de inhoud van allesoverantistolling.nl? We horen dat graag. Je kunt jouw vraag of opmerking doorgeven via onderstaand contactformulier. We nemen dan contact met je op." />
      <div style={{ backgroundColor: "#F6F4F5" }}>
        <SectionWrapper padded>
          <Grid>
            <Row>
              <Col>
                <ContactFormProf />
              </Col>
            </Row>
          </Grid>
        </SectionWrapper>
      </div>
    </LayoutMain>
  );
};

export default ContactPage;
